@if (loading) {
  <mat-spinner [strokeWidth]="3" [diameter]="50" class="m-auto"></mat-spinner>
} @else {
  <div class="p-[0.5in] bg-white w-[11in] m-auto">
    <div id="training_report" class="bg-white m-auto flex flex-col gap-6">
      <header class="flex justify-center mb-2 items-center gap-6">
        <img
          [src]="
            (user && user.company && user.company.logo) ||
            (company && company.logo)
          "
          alt="company logo"
          class="h-14 w-20 object-cover"
        />
        <h1>Training Report</h1>
      </header>
      <!-- TRAINING INFO -->
      @if (training) {
        <div class="flex flex-col gap-2">
          <h2>{{ training.name }}</h2>
          <div class="flex gap-2 items-baseline">
            <h5>Created by:</h5>
            <p>
              {{ training.created_by?.fullname }},
              {{ training.created_at | date: 'HH:mm, dd/MM/yyyy' }}
            </p>
          </div>
          <div class="flex gap-2 items-baseline">
            <h5>Status:</h5>
            <span
              class=" rounded-lg text-xs py-1.5 px-2.5 cat-badge-status-{{
                training.status
              }}"
              >{{ training.status }}</span
            >
          </div>
          <div>
            <h5>Description:</h5>
            <p>{{ training.description }}</p>
          </div>
        </div>
      }

      <!-- GROUP REPORT -->
      @if (groupReport && !participantReport && group) {
        <div class="flex gap-2">
          <h5>Department:</h5>
          <p>{{ group.name }}</p>
        </div>
        <cat-ai-table
          [rows]="groupReport"
          [config]="groupConfig"
          tableClasses="document"
        ></cat-ai-table>
      }
      <!-- GROUP PARTICIPANTS REPORT -->
      @if (groupParticipantReport && !participantReport) {
        <h4>User Department Statistics</h4>

        <cat-ai-table
          [rows]="groupParticipantReport"
          [config]="groupParticipantsConfig"
          tableClasses="document"
        ></cat-ai-table>
      }
      <!-- PARTICIPANT REPORT -->
      @if (participantReport) {
        @if (user) {
          <div class="flex justify-between">
            <div class="flex">
              @if (user.avatar) {
                <img
                  [src]="user.avatar"
                  alt="User avatar"
                  class="h-8 w-8 object-cover rounded-full mr-4"
                />
              }
              <div class="flex flex-col">
                <h5>{{ user.fullname }}</h5>
                <p>{{ user.email }}</p>
              </div>
            </div>
            <div class="flex gap-3">
              <p>
                Department:
                @for (group of user.groups; track $index) {
                  {{ group.name }} {{ $last ? '' : ',' }}
                }
              </p>
              <p>
                Job category: {{ user.job_category && user.job_category.name }}
              </p>
            </div>
          </div>
        }

        <cat-ai-table
          [rows]="participantReport"
          [config]="participantConfig"
          tableClasses="document"
        ></cat-ai-table>
      }
      <div class="text-black">
        <div class="flex justify-between">
          <h4>Quiz Performance:</h4>
          <div class="flex gap-4">
            <div class="flex gap-2">
              <div class="aspect-square h-4 bg-success-500 rounded-xs"></div>
              <h5>Answered</h5>
              <p class="font-semibold">
                {{ correctAnswers }}
              </p>
            </div>
            <div class="flex gap-2">
              <div class="aspect-square h-4 bg-error rounded-xs"></div>
              <h5>Failed</h5>
              <p class="font-semibold">
                {{ failedAnswers }}
              </p>
            </div>
          </div>
        </div>

        <div class="p-3">
          <cat-ai-questions-list
            [questions]="training && training.questions"
            [answers]="attempt && attempt.answers"
            [documentMode]="true"
          ></cat-ai-questions-list>
        </div>
      </div>
      <div>
        <h4>Materials:</h4>
        <ul class="list-disc pl-4 mt-3">
          @for (material of training?.materials; track $index) {
            <li class="text-black font-semibold">
              @if (material.file) {
                {{ material.file.url | fileNameFromUrl }}
              } @else {
                {{
                  (material.material && material.material.title) || 'no_name'
                }}
              }
            </li>
          }
        </ul>
      </div>
      <div>
        <h4>Summary:</h4>
        <p class="text-black !mt-3">
          @if (participantReport && user) {
            {{ user.fullname }}
          } @else {
            {{ group && group.name }}
          }
          has successfully completed the {{ training?.name }},
          {{ training?.description }}
        </p>
      </div>
    </div>
  </div>
}
