<div class="flex flex-col gap-6 p-6">
  <div class="flex justify-between">
    <cat-ai-search (search)="updateSearch($event)"></cat-ai-search>
  </div>
  <cat-ai-trainings-list
    [data]="data$ | async"
    [loading]="loading"
    (scrollDown)="onScrollDown()"
  >
  </cat-ai-trainings-list>
</div>
