import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TableConfig, UrlPrefixPipe } from '@cat-ai-us-fe/shared/util';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CollapsibleCardComponent } from '@cat-ai-us-fe/shared/ui';
import {
  chartOptions,
  GROUP_REPORTS_TABLE_CONFIG,
  PARTICIPANTS_REPORTS_TABLE_CONFIG,
  PARTICIPANTS_TABLE_CONFIG,
} from './config';
import {
  Certificate,
  CompanyGroup,
  JobCategory,
  Participant,
  PatchedPublicTrainingRequest,
  PublicTraining,
  TrainingGroup,
  TrainingParticipants,
  TrainingsParticipantsListRequestParams,
  User,
} from '@cat-ai-us-fe/api';
import {
  MaterialsListComponent,
  QuestionsListComponent,
  TrainingDetailedStatsComponent,
  TrainingParticipantsTabComponent,
  TrainingReportsTabComponent,
} from '@cat-ai-us-fe/trainings/ui';
import { MatDialog } from '@angular/material/dialog';
import { AddParticipantsModalComponent } from '../../modals/add-participants-modal/add-participants-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActiveUserState,
  SupervisorAccessDirective,
} from '@cat-ai-us-fe/shared/data-access';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { Store } from '@ngxs/store';

@Component({
  selector: 'cat-ai-training-details',
  standalone: true,
  imports: [
    MatIcon,
    MatButton,
    MatTabsModule,
    MatProgressSpinnerModule,
    QuestionsListComponent,
    CollapsibleCardComponent,
    AsyncPipe,
    DatePipe,
    RouterLink,
    MaterialsListComponent,
    SupervisorAccessDirective,
    TrainingDetailedStatsComponent,
    TrainingReportsTabComponent,
    TrainingParticipantsTabComponent,
    UrlPrefixPipe,
  ],
  providers: [AsyncPipe],
  templateUrl: './training-details.component.html',
})
export class TrainingDetailsComponent implements OnInit {
  chart: Highcharts.Options = chartOptions;
  participantsTableConfig: TableConfig<Participant> = PARTICIPANTS_TABLE_CONFIG;
  groupReportsTableConfig = GROUP_REPORTS_TABLE_CONFIG;
  participantsReportsTableConfig = PARTICIPANTS_REPORTS_TABLE_CONFIG;
  loading = false;
  loadingParticipants = false;
  loadingCertificate = false;
  loadingGroupReports = false;
  loadingParticipantsReports = false;
  trainingId!: number;
  currentTraining$!: Observable<SupervisorTraining>;
  participants$!: Observable<Participant[]>;
  participantsReports$!: Observable<TrainingParticipants[]>;
  groupReports$!: Observable<TrainingGroup[]>;
  departments$!: Observable<CompanyGroup[]>;
  jobCategories$!: Observable<JobCategory[]>;
  certificate$!: Observable<Certificate>;

  TrainingStatus = PublicTraining.StatusEnum;

  private destroyRef = inject(DestroyRef);
  private participantsfFilters$ =
    new BehaviorSubject<TrainingsParticipantsListRequestParams>({} as any);
  private groupReportsFilters$ = new BehaviorSubject<any>({});
  private participantsReportsFilters$ = new BehaviorSubject<any>({});

  constructor(
    private apiService: TrainingsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.handleDataStream();
    this.getSelectorsData();
  }

  backToList() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  archieve() {
    this.apiService
      .updateTraining(Number(this.trainingId), {
        status: PatchedPublicTrainingRequest.StatusEnum.Archived,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.backToList());
  }

  openParticipantsModal() {
    const dialodRef = this.dialog
      .open(AddParticipantsModalComponent, {
        height: '800px',
        minWidth: '1040px',
        data: {
          trainingId: this.trainingId,
        },
      })
      .afterClosed();

    dialodRef
      .pipe(
        filter((res) => res && res.length),
        switchMap((selectedUsers: User[]) => {
          const ids = selectedUsers.map((user) => user.id);
          return this.apiService.assignTrainingParticipants(this.trainingId, {
            ids,
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateParticipantsFilters();
      });
  }

  viewUserTasks(user: Participant) {
    this.router.navigate(['task-manager'], {
      queryParams: { assignee: user.id, type: 'Scheduled' },
    });
  }

  gotoGroupMembers(row: { id: number }) {
    this.router.navigate([`./reports/${row.id}/list`], {
      relativeTo: this.route,
    });
  }

  gotoMemberReport(row: { id: number }) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`./reports/member/${row.id}`], {
        relativeTo: this.route,
      }),
    );
    window.open(url, '_blank');
  }

  gotoGroupReport(row: { id: number }) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`./reports/${row.id}`], {
        relativeTo: this.route,
      }),
    );
    window.open(url, '_blank');
  }

  gotoCertificate(uuid: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`./certificate/${uuid}`], {
        relativeTo: this.route,
      }),
    );
    window.open(url, '_blank');
  }

  updateParticipantsFilters(
    filters: Partial<TrainingsParticipantsListRequestParams> = {},
  ) {
    this.participantsfFilters$.next({
      ...this.participantsfFilters$.value,
      ...filters,
    });
  }

  updateGroupReportsFilters(filters: Partial<any> = {}) {
    this.groupReportsFilters$.next({
      ...this.groupReportsFilters$.value,
      ...filters,
    });
  }

  updateParticipantsReportsFilters(filters: Partial<any> = {}) {
    this.participantsReportsFilters$.next({
      ...this.participantsReportsFilters$.value,
      ...filters,
    });
  }

  private handleDataStream() {
    this.loading = true;
    this.loadingCertificate = true;
    this.loadingGroupReports = true;
    this.loadingParticipantsReports = true;
    this.loadingParticipants = true;

    this.setCurrentTraining();
  }

  private setChartData(training: SupervisorTraining) {
    if (this.chart.title) {
      Object.assign(this.chart.title, {
        text: `Users  <br> <b>${training.stats?.total_participants}</b>`,
      });
    }
    if (training.stats) {
      const not_started = Math.max(
        (training.stats.total_participants || 0) -
          (training.stats.passed || 0) -
          (training.stats.in_progress || 0),
        0,
      );
      Object.assign(training.stats, { not_started });
    }

    this.chart.series?.push({
      type: 'pie',
      name: 'Users',
      innerSize: '70%',
      data: [
        ['Completed', training.stats?.passed],
        ['In Progress', training.stats?.in_progress],
        ['Not Started', training.stats?.not_started],
        ['Failed', training.stats?.failed],
      ],
    });
  }

  private getSelectorsData() {
    this.jobCategories$ = this.apiService.getJobCategories();
    this.departments$ = this.apiService.getDepartments();
  }

  private setCurrentTraining() {
    this.currentTraining$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter((id): id is string => !!id),
      tap((id) => {
        this.trainingId = +id;
        this.participantsfFilters$.next({ trainingId: +id });
      }),
      switchMap((id) => this.apiService.getTraining(+id)),
      tap((res) => this.setChartData(res)),
      tap(() => this.setParticipants()),
      tap(() => this.setReports()),
      tap(() => this.checkCerfiticate()),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private checkCerfiticate() {
    const userId = this.store.selectSnapshot(ActiveUserState.currentUser).id;
    if (!userId) {
      return;
    }
    this.certificate$ = this.apiService
      .getAttempts({
        training: this.trainingId,
        user: userId,
        status: 'passed',
      })
      .pipe(
        filter((res) => !!res.results.length),
        map((res) => res.results[0].certificate),
        finalize(() => (this.loadingCertificate = false)),
      );
  }

  private setParticipants() {
    this.participants$ = this.participantsfFilters$.pipe(
      tap(() => (this.loadingParticipants = true)),
      switchMap((filters) => this.apiService.getParticipants(filters)),
      tap((pdata) => {
        this.participantsTableConfig.pagination.itemsCount = pdata.count;
        this.loadingParticipants = false;
      }),
      map((r) => r.results),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private setReports() {
    this.groupReports$ = this.groupReportsFilters$.pipe(
      switchMap((filters) =>
        this.apiService.getGroupsReport({ id: this.trainingId, ...filters }),
      ),
      tap((pdata) => {
        this.groupReportsTableConfig.pagination.itemsCount = pdata.count;
        this.loading = false;
      }),
      map((r) => r.results),
      tap(() => (this.loadingGroupReports = false)),
      takeUntilDestroyed(this.destroyRef),
    );
    this.participantsReports$ = this.participantsReportsFilters$.pipe(
      switchMap((filters) =>
        this.apiService.getParticipantsReport({
          id: this.trainingId,
          ...filters,
        }),
      ),
      tap((pdata) => {
        this.participantsReportsTableConfig.pagination.itemsCount = pdata.count;
        this.loading = false;
      }),
      map((r) => r.results),
      tap(() => (this.loadingParticipantsReports = false)),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
