import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cat-ai-pass-quiz-intro',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, RouterLink, DatePipe],
  templateUrl: './pass-quiz-intro.component.html',
})
export class PassQuizIntroComponent {
  @Input() training!: SupervisorTraining | null;
  @Output() startQuiz = new EventEmitter();
}
