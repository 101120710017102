<div class="rounded-sm shadow-sm bg-white p-1 !m-1">
  <div class="py-4 px-6 flex-wrap flex-row gap-2">
    <div class="flex flex-row items-center gap-2 justify-between">
      <cat-ai-search
        wrapperClasses="w-[300px]"
        (search)="updateFilters.emit({ search: $event, page: 1 })"
      ></cat-ai-search>
      <div class="flex flex-row shrink-0 items-center gap-3">
        <button mat-button color="accent">
          <mat-icon>download</mat-icon>
          Download .CSV
        </button>
        <button
          mat-flat-button
          color="basic"
          (click)="openParticipantsModal.emit()"
        >
          <mat-icon>add</mat-icon>
          Assign Participants
        </button>
      </div>
    </div>
    <div class="my-3 w-full h-[1px] bg-gray-200"></div>
    <div class="flex flex-row gap-3">
      <cat-ai-selector
        label="Job Category"
        [options]="jobCategories"
        (change)="updateFilters.emit({ jobCategory: $event })"
        [resetOption]="true"
        wrapperClasses="shadow-xs w-[200px]"
      ></cat-ai-selector>
      <cat-ai-selector
        label="Department"
        [options]="departments"
        (change)="updateFilters.emit({ groups: $event })"
        [resetOption]="true"
        wrapperClasses="shadow-xs"
        class="w-[200px]"
      ></cat-ai-selector>
    </div>
  </div>
  <cat-ai-table
    [rows]="participants"
    [config]="tableConf"
    [isLoading]="loading"
    (pageChange)="updateFilters.emit({ page: $event })"
    (perPageChange)="updateFilters.emit({ pageSize: $event, page: 1 })"
    (search)="viewUserTasks.emit($event)"
  ></cat-ai-table>
</div>
