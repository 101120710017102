import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CertificatePublic, User } from '@cat-ai-us-fe/api';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';

@Component({
  selector: 'cat-ai-certificate-snapshot',
  standalone: true,
  imports: [MatProgressSpinnerModule, DatePipe],
  templateUrl: './certificate-snapshot.component.html',
})
export class CertificateSnapshotComponent implements OnChanges {
  @Input() user!: User | null;
  @Input() training!: SupervisorTraining | null;
  @Input() certificate!: CertificatePublic | null;
  @Output() updateLoadingState = new EventEmitter();
  loading = false;

  ngOnChanges(): void {
    this.loading = !this.user || !this.training || !this.certificate;
    this.updateLoadingState.emit(this.loading);
  }
}
