<div class="grid grid-cols-3 gap-2 bg-white rounded-sm shadow-sm p-6 !m-1">
  @for (material of materials; track $index) {
    @if (material.material; as materialLink) {
      <div
        class="flex flex-col items-start gap-4 max-h-fit p-4 border rounded-md border-gray-300"
        routerLink="materials/{{ materialLink.id }}"
      >
        <p
          class="font-semibold text-base text-gray-700 w-full whitespace-nowrap max-w-full overflow-hidden text-ellipsis"
        >
          @if (materialLink.type === MaterialTypeEnum.File) {
            {{ material.file.url | fileNameFromUrl }}
          } @else {
            {{ materialLink?.title || 'no_title' }}
          }
        </p>
        <div class="flex justify-between w-full items-center">
          @if (materialLink?.type; as materialType) {
            <div
              class="flex flex-row gap-1 rounded-lg text-xs py-1.5 px-2.5 cat-material-badge-{{
                materialType
              }}"
            >
              <mat-icon
                class="material-icons-outlined text-base !w-4 !h-4 leading-none"
                >{{ materialIcons[materialType] }}</mat-icon
              >
              <p>{{ materialType | lowercase | titlecase }}</p>
            </div>
          }

          <div>
            <p class="text-sm">
              {{ materialLink?.created_at | date: 'dd/MM/yyyy' }}
            </p>
          </div>
        </div>
      </div>
    }
  }
</div>
