import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormlyModule } from '@ngx-formly/core';
import { FORM_FIELDS, FORM_MODEL } from './generator-form.const';
import { MatIconModule } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';
import { QuestionFormComponent } from '@cat-ai-us-fe/trainings/ui';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import {
  GenerateQuizRequest,
  PrivateQuestion,
  PublicTraining,
} from '@cat-ai-us-fe/api';
import { TrainingFormType } from '@cat-ai-us-fe/trainings/util';
import { map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonLoadingDirective } from '@cat-ai-us-fe/shared/util';

type LocalQuestion = (PrivateQuestion | Partial<PrivateQuestion>) & {
  customId?: number;
};

@Component({
  selector: 'cat-ai-generate-quiz-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    NgTemplateOutlet,
    QuestionFormComponent,
    MatButtonLoadingDirective,
  ],
  templateUrl: './generate-quiz-modal.component.html',
})
export class GenerateQuizModalComponent {
  form = new FormGroup({});
  model = FORM_MODEL;
  fields = FORM_FIELDS;
  questions: LocalQuestion[] = [];
  generating = false;
  private apiService = inject(TrainingsService);
  private dialogRef = inject(MatDialogRef);
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      trainingForm: TrainingFormType;
      training: PublicTraining;
      questions: PrivateQuestion[];
    },
  ) {
    this.setPrefilledQuestions();
  }

  create() {
    const value: Partial<PrivateQuestion>[] = this.questions.map((q) => {
      delete q.customId;
      return q;
    });
    this.dialogRef.close({
      questions: value,
      ...this.model,
    });
  }

  addQuestion(index = 0) {
    const newQuestion: LocalQuestion = {
      text: '',
      score: 0,
      variants: [],
      customId: Date.now(),
    };
    if (this.data.training?.id) {
      Object.assign(newQuestion, { training: this.data.training.id });
    }
    this.questions.splice(index + 1, 0, newQuestion);
  }

  update(question: LocalQuestion, index: number) {
    const updQuestion = {
      ...question,
      customId: question.customId,
    };
    this.questions[index] = updQuestion;
  }

  remove(index: number, question: LocalQuestion) {
    if (this.data.training?.id && question.id) {
      this.apiService
        .deleteQuestion(this.data.training.id, question.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.questions.splice(index, 1));
    }
    this.questions.splice(index, 1);
  }

  generateQuestions() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.generating = true;
    const { name, description, materials } = this.data.trainingForm;
    const payload: GenerateQuizRequest = {
      ...(this.form.value as Partial<GenerateQuizRequest> as any),
      questions: this.questions || [],
      name,
      description,
      materials: materials.map((m) => m.value || m),
    };

    this.apiService
      .generateQuestions(payload)
      .pipe(
        map((questions) => questions?.map((q) => this.setCustomId(q as any))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res) => {
        this.generating = false;
        this.questions = res;
      });
  }

  private setPrefilledQuestions() {
    if (this.data.questions?.length) {
      this.questions = this.data.questions.map((q) => this.setCustomId(q));
    }
  }

  private setCustomId(question: Partial<PrivateQuestion>): LocalQuestion {
    return {
      ...question,
      customId: Date.now(),
    };
  }
}
