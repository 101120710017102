<div class="w-full px-6">
  <!-- Top nav -->
  <div class="flex justify-between my-6">
    <button mat-button (click)="backToList()">
      <mat-icon class="relative -top-0.5">chevron_left</mat-icon>
      Back to all trainings
    </button>
    <div *catAiSupervisorAccess class="flex gap-2 items-center">
      <button
        mat-button
        color="warn"
        class="min-w-[100px]"
        (click)="archieve()"
      >
        Archieve
      </button>
      <button
        routerLink="./builder"
        mat-flat-button
        color="basic"
        class="min-w-[100px]"
      >
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
      <button
        mat-flat-button
        color="accent"
        class="min-w-[100px]"
        disabled="true"
      >
        Preview
      </button>
    </div>
  </div>
  <!-- Main info -->

  @if (currentTraining$ | async; as training) {
    <div class="p-6 bg-white rounded-sm shadow-sm flex flex-col gap-6">
      @if (training.cover) {
        <div
          class="rounded-md h-64 bg-cover bg-gray-100 w-full bg-center"
          [style.background-image]="'url(' + (training.cover | urlPrefix) + ')'"
        ></div>
      }
      <!-- Top info -->
      <div class="max-w-5xl text-gray-700">
        <div class="flex flex-col gap-1 mb-4">
          <p class="text-gray-500 text-xs">#{{ training.id }}</p>
          <h3 class="font-semibold">{{ training.name }}</h3>
          <div class="flex gap-4 text-gray-500 text-sm">
            <p>Created By: {{ training.created_by?.fullname }}</p>
            <p>
              {{ training.created_at | date: 'HH:mm, dd/MM/yyyy' }}
            </p>
          </div>
        </div>
        <p class="text-md text-gray-700">
          {{ training.description }}
        </p>
      </div>
      <cat-ai-training-detailed-stats
        *catAiSupervisorAccess
        [chart]="chart"
        [stats]="training.stats"
      ></cat-ai-training-detailed-stats>
      @if (training.status !== TrainingStatus.Draft) {
        @if (certificate$ | async; as certificate) {
          <button
            (click)="gotoCertificate(certificate.uuid)"
            mat-flat-button
            color="accent"
            class="place-self-start"
          >
            Certificate
          </button>
        } @else {
          @if (loadingCertificate) {
            <mat-spinner [strokeWidth]="3" [diameter]="35"></mat-spinner>
          } @else {
            @if (training.status === TrainingStatus.Active) {
              <button
                routerLink="./pass-quiz"
                mat-flat-button
                color="accent"
                class="place-self-start"
              >
                Take Quiz
              </button>
            }
          }
        }
      }
    </div>

    <div class="w-full">
      <mat-tab-group mat-stretch-tabs="false" class="my-6 text-gray-500">
        <mat-tab label="Training Materials">
          <cat-ai-materials-list
            [materials]="training.materials"
          ></cat-ai-materials-list>
        </mat-tab>
        <mat-tab *catAiSupervisorAccess label="Participants">
          <cat-ai-training-participants-tab
            [tableConf]="participantsTableConfig"
            [jobCategories]="jobCategories$ | async"
            [departments]="departments$ | async"
            [participants]="participants$ | async"
            [loading]="loadingParticipants"
            (updateFilters)="updateParticipantsFilters($event)"
            (openParticipantsModal)="openParticipantsModal()"
            (viewUserTasks)="viewUserTasks($event)"
          ></cat-ai-training-participants-tab>
        </mat-tab>
        <mat-tab *catAiSupervisorAccess label="Quiz">
          @if (training) {
            <cat-ai-collapsible-card wrapperClasses="bg-gray-25 shadow-xs">
              <div header class="flex items-center gap-4">
                <h4>Quiz</h4>
                <p class="text-base text-gray-700">
                  Score: {{ training.pass_score }}
                </p>
                <p class="text-base text-gray-700">
                  Time for quiz:
                  {{
                    (training.seconds_to_complete || 0) * 1000
                      | date: 'HH:mm:ss' : 'UTC'
                  }}
                </p>
              </div>
              <cat-ai-questions-list
                [questions]="training.questions"
              ></cat-ai-questions-list>
            </cat-ai-collapsible-card>
          }
        </mat-tab>
        @if (training.status !== TrainingStatus.Draft) {
          <mat-tab *catAiSupervisorAccess label="Participants reports">
            <cat-ai-training-reports-tab
              [tableConf]="participantsReportsTableConfig"
              [data]="participantsReports$ | async"
              [departments]="departments$ | async"
              (updateFilters)="updateParticipantsReportsFilters($event)"
              (openReport)="gotoMemberReport($event)"
              [loading]="loadingParticipantsReports"
            ></cat-ai-training-reports-tab>
          </mat-tab>
          <mat-tab *catAiSupervisorAccess label="Group reports">
            <cat-ai-training-reports-tab
              [tableConf]="groupReportsTableConfig"
              [data]="groupReports$ | async"
              [departments]="departments$ | async"
              (updateFilters)="updateGroupReportsFilters($event)"
              (openGroupMembers)="gotoGroupMembers($event)"
              (openReport)="gotoGroupReport($event)"
              [loading]="loadingGroupReports"
            ></cat-ai-training-reports-tab>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  } @else {
    @if (loading) {
      <div class="w-full flex items-center justify-center">
        <mat-spinner [strokeWidth]="3" [diameter]="50"></mat-spinner>
      </div>
    }
  }
</div>
