import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { MatButtonModule } from '@angular/material/button';
import { PassQuizQuestionFormComponent } from '../pass-quiz-question-form/pass-quiz-question-form.component';
import { Observable, interval, map, takeUntil, timer } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TrainingsQuestionsAnswerUpdateRequestParams } from '@cat-ai-us-fe/api';
import { QuizNavigationComponent } from '../quiz-navigation/quiz-navigation.component';

@Component({
  selector: 'cat-ai-pass-quiz-questions',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    PassQuizQuestionFormComponent,
    AsyncPipe,
    QuizNavigationComponent,
  ],
  templateUrl: './pass-quiz-questions.component.html',
})
export class PassQuizQuestionsComponent implements OnChanges {
  @Input() training!: SupervisorTraining | null;
  @Output() finishAttempt = new EventEmitter();
  @Output() closeQuiz = new EventEmitter();
  @Output() answerQuestion = new EventEmitter();
  countdown$!: Observable<string>;
  uniqueId = 'pass-quiz';

  ngOnChanges(): void {
    if (this.training?.seconds_to_complete) {
      this.setCountdownTimer();
    }
  }

  updateQuestionAnswer(ids: number[], id: number) {
    const payload: TrainingsQuestionsAnswerUpdateRequestParams = {
      trainingId: this.training?.id as number,
      id,
      linkedEntitiesRequest: { ids },
    };
    this.answerQuestion.emit(payload);
  }

  private setCountdownTimer() {
    const ONE_SECOND = 1000;
    const ONE_MINUTE = ONE_SECOND * 60;
    const ONE_HOUR = ONE_MINUTE * 60;
    const HOURS_IN_DAY = 24;
    let distance =
      (this.training?.seconds_to_complete || 0) * ONE_SECOND - ONE_SECOND;

    this.countdown$ = interval(ONE_SECOND).pipe(
      takeUntil(timer(distance)),
      map(() => {
        const hours = Math.floor(
          (distance % (ONE_HOUR * HOURS_IN_DAY)) / ONE_HOUR,
        );
        const minutes = Math.floor((distance % ONE_HOUR) / ONE_MINUTE);
        const seconds = Math.floor((distance % ONE_MINUTE) / ONE_SECOND);
        distance -= ONE_SECOND;
        return `${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 's' : ''}`;
      }),
    );
  }
}
