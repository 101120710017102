import { Participant, User } from '@cat-ai-us-fe/api';
import { TableConfig } from '@cat-ai-us-fe/shared/util';

export const TABLE_CONFIG: TableConfig<User | Participant> = {
  columns: [
    {
      label: 'Name',
      html: (row) => `<p>${row.fullname}</p><p>${row.email}</p>`,
    },
    {
      label: 'Job category',
      html: (row) => row.job_category?.name || '-',
    },
    {
      label: 'Department',
      html: (row) => row.company?.name,
    },
  ],
  pagination: { page: 1, perPage: 10 },
  actions: { remove: true },
};
