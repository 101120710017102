import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { TableComponent } from '@cat-ai-us-fe/shared/ui';
import {
  Attempt,
  Company,
  CompanyGroup,
  QuizAnswer,
  TrainingGroup,
  TrainingParticipants,
  User,
} from '@cat-ai-us-fe/api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  GROUP_PARTICIPANTS_TABLE_CONFIG,
  GROUP_TABLE_CONFIG,
  PARTICIPANT_TABLE_CONFIG,
} from './config';
import { FileNameFromUrlPipe } from '@cat-ai-us-fe/shared/util';
import {
  LocalPrivateQuestion,
  QuestionsListComponent,
} from '../questions-list/questions-list.component';

@Component({
  selector: 'cat-ai-training-report-snapshot',
  standalone: true,
  imports: [
    DatePipe,
    MatProgressSpinnerModule,
    TableComponent,
    FileNameFromUrlPipe,
    QuestionsListComponent,
  ],
  templateUrl: './training-report-snapshot.component.html',
})
export class TrainingReportSnapshotComponent implements OnChanges {
  @Input() training!: SupervisorTraining | null;
  @Input() groupReport!: TrainingGroup[] | null;
  @Input() groupParticipantReport!: TrainingParticipants[] | null;
  @Input() participantReport!: TrainingParticipants[] | null;
  @Input() attempt!: Attempt | null;
  @Input() group!: CompanyGroup | null;
  @Input() user!: User | null;
  @Input() company!: Company | null;
  @Output() updateLoadingState = new EventEmitter();
  groupConfig = GROUP_TABLE_CONFIG;
  groupParticipantsConfig = GROUP_PARTICIPANTS_TABLE_CONFIG;
  participantConfig = PARTICIPANT_TABLE_CONFIG;
  correctAnswers = 0;
  failedAnswers = 0;

  loading = false;

  ngOnChanges(): void {
    const groupReportLoading =
      !this.groupReport ||
      !this.groupParticipantReport ||
      !this.group ||
      !this.company;
    const userReportLoading = !this.participantReport || !this.user;
    this.loading =
      (!this.training || !this.attempt) &&
      (userReportLoading || groupReportLoading);
    this.updateLoadingState.emit(this.loading);
    if (this.training && this.attempt) {
      this.mapQuestions();
    }
  }

  private mapQuestions() {
    if (!this.attempt?.answers) {
      return;
    }
    this.training?.questions?.forEach((q: LocalPrivateQuestion) => {
      const answer = this.attempt?.answers?.find(
        (a: QuizAnswer) => (a.question as any).id === q.id,
      );
      if (!answer) {
        return;
      }
      q.variants.map((v) => {
        const selectedOption = answer.choice.id === v.id;
        v.correctOption = selectedOption && answer.choice.correct;
        v.nonCorrectOption = selectedOption && !answer.choice.correct;
        return v;
      });
      q.hasCorrectAnswer = q.variants.some((v) => v.correctOption);
      q.hasUncorrentAnswer = q.variants.some((v) => v.nonCorrectOption);
    });
    this.correctAnswers =
      this.training?.questions?.filter((q: LocalPrivateQuestion) =>
        q.variants.some((v) => v.correctOption),
      ).length || 0;
    this.failedAnswers =
      this.training?.questions?.filter((q: LocalPrivateQuestion) =>
        q.variants.some((v) => v.nonCorrectOption),
      ).length || 0;
  }
}
