<div
  id="quiz-question-{{ uniqueId }}-{{ question.id }}"
  class="flex flex-col gap-6 bg-white rounded-m shadow-sm p-6"
>
  <div class="flex justify-between">
    <h4>Question &#x2116;{{ index + 1 }}</h4>
    <p class="text-gray-700">{{ question.score }} Points</p>
  </div>
  <div>
    <h3>{{ question.text }}</h3>
    <p class="text-gray-500">Not answered yet</p>
  </div>
  <div>
    <p class="text-gray-500">
      @if (question.single) {
        Select one of the following
      } @else {
        Select several options
      }
    </p>
    <div class="flex flex-col gap-2">
      @for (variant of question.variants; track variant.id) {
        @if ({ selected: false, id: variant.id }; as state) {
          <button
            class="px-3 py-2 rounded-xs border"
            [class.bg-primary-25]="state.selected"
            (click)="chooseOption(variant, state)"
          >
            <div class="flex justify-between items-center">
              <p [class.text-primary-700]="state.selected">
                {{ variant.text }}
              </p>
              @if (state.selected) {
                <mat-icon
                  color="accent"
                  class="!w-4 !h-4 !leading-none !text-base"
                  >check_circle</mat-icon
                >
              }
            </div>
          </button>
        }
      }
    </div>
  </div>
</div>
