import { Component, DestroyRef, Input, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Notification } from '@cat-ai-us-fe/api';
import { NotificationsListComponent } from '@cat-ai-us-fe/shared/ui';
import { NotificationService } from '@cat-ai-us-fe/shared/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cat-ai-training-notifications',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, NotificationsListComponent],
  templateUrl: './training-notifications.component.html',
})
export class TrainingNotificationsComponent {
  @Input() maxHeight!: number;
  @Input() notifications!: Notification[] | null;
  private destroyRef = inject(DestroyRef);

  constructor(public notificationService: NotificationService) {}

  dismiss(id: number) {
    this.notificationService
      .dismiss(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (!this.notifications) {
          return;
        }

        this.notifications.splice(
          this.notifications.findIndex((el) => el.id === data.id),
          1,
        );
      });
  }
}
