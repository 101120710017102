import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
  SearchComponent,
  SelectorComponent,
  TableComponent,
} from '@cat-ai-us-fe/shared/ui';
import { CompanyGroup, JobCategory } from '@cat-ai-us-fe/api';
import { TableConfig } from '@cat-ai-us-fe/shared/util';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cat-ai-training-reports-tab',
  standalone: true,
  imports: [
    MatIcon,
    TableComponent,
    SearchComponent,
    SelectorComponent,
    MatButtonModule,
  ],
  templateUrl: './training-reports-tab.component.html',
})
export class TrainingReportsTabComponent {
  @Input() data!: any[] | null;
  @Input() departments!: CompanyGroup[] | null;
  @Input() jobCategories!: JobCategory[] | null;
  @Input() loading = false;
  @Input() tableConf!: TableConfig<any>;
  @Output() updateFilters = new EventEmitter<Partial<any>>();
  @Output() openParticipantsModal = new EventEmitter();
  @Output() openReport = new EventEmitter();
  @Output() openGroupMembers = new EventEmitter();
}
