import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MaterialsDownloadRetrieve200Response } from '@cat-ai-us-fe/api';
import { MaterialsService } from '@cat-ai-us-fe/material/data-access';
import { DEFAULT_NOTIFICATION_DISMISS_DURATION } from '@cat-ai-us-fe/shared/data-access';
import {
  MaterialPreviewComponent,
  NotificationCardComponent,
} from '@cat-ai-us-fe/shared/ui';
import { Observable, filter, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'cat-ai-material-view',
  standalone: true,
  imports: [
    MaterialPreviewComponent,
    AsyncPipe,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
  ],
  templateUrl: './material-view.component.html',
})
export class MaterialViewComponent implements OnInit {
  materialUrl$!: Observable<MaterialsDownloadRetrieve200Response>;
  loadingFile = false;

  constructor(
    private apiService: MaterialsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.loadingFile = true;

    this.materialUrl$ = this.route.paramMap.pipe(
      map((params) => Number(params.get('materialId'))),
      filter((id): id is number => !!id),
      switchMap((id) => this.apiService.downloadFile(id)),
      tap(() => (this.loadingFile = false)),
    );
  }

  handleFileViewError(err: { message: string; name: string }) {
    const notification = {
      title: (err && err.name) || 'File cannot be accessed',
      message: err && err.message,
      urgency: 'high',
    };
    this.snackBar.openFromComponent(NotificationCardComponent, {
      data: { notification },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar',
      duration: DEFAULT_NOTIFICATION_DISMISS_DURATION,
    });
  }
}
