import {
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  HighchartsChartComponent,
  HighchartsChartModule,
} from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { STATS_MODEL, chartOptions, config } from './config';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  SearchComponent,
  TableComponent,
  SelectorComponent,
} from '@cat-ai-us-fe/shared/ui';
import {
  BehaviorSubject,
  concatMap,
  finalize,
  map,
  Observable,
  tap,
} from 'rxjs';
import {
  DetailTaskRead,
  Notification,
  PublicTraining,
  TaskRead,
  TasksListRequestParams,
  User,
} from '@cat-ai-us-fe/api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  EnumToOptionsPipe,
  SecondsToTimePipe,
} from '@cat-ai-us-fe/shared/util';
import {
  TrainingNotificationsComponent,
  TrainingsListComponent,
} from '@cat-ai-us-fe/trainings/ui';
import {
  ActiveUserState,
  RoleAccessDirective,
  SupervisorAccessDirective,
} from '@cat-ai-us-fe/shared/data-access';
import { Store } from '@ngxs/store';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { TasksService } from '@cat-ai-us-fe/tasks';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
@Component({
  selector: 'cat-ai-trainings-dasboard-view',
  standalone: true,
  imports: [
    AsyncPipe,
    HighchartsChartModule,
    MatIconModule,
    MatButtonModule,
    SearchComponent,
    TableComponent,
    SearchComponent,
    SelectorComponent,
    MatProgressSpinnerModule,
    EnumToOptionsPipe,
    TrainingNotificationsComponent,
    SupervisorAccessDirective,
    RoleAccessDirective,
    TrainingsListComponent,
    SecondsToTimePipe,
  ],
  templateUrl: './trainings-dasboard-view.component.html',
  providers: [AsyncPipe],
})
export class TrainingsDasboardViewComponent implements OnInit {
  @ViewChild(HighchartsChartComponent) chartComp!: HighchartsChartComponent;
  RoleEnum = User.RoleEnum;
  chart: Highcharts.Options = chartOptions;
  Highcharts: typeof Highcharts = Highcharts;
  tableConf = config;
  tasks$!: Observable<DetailTaskRead[]>;
  notifications$!: Observable<Notification[]>;
  loadingTasks = false;
  loadingTrainings = false;
  TaskInternalStatuses = TaskRead.InternalStatusEnum;
  role = this.store.selectSnapshot(ActiveUserState.getRole);
  activeTrainings$!: Observable<PublicTraining[]>;
  stats!: typeof STATS_MODEL;
  private filterParams$ = new BehaviorSubject<TasksListRequestParams>({
    page: this.tableConf.pagination.page,
    pageSize: this.tableConf.pagination.perPage,
  });
  private currentUserId = this.store.selectSnapshot(ActiveUserState.currentUser)
    .id;
  private destroyRef = inject(DestroyRef);

  constructor(
    private taskService: TasksService,
    private apiService: TrainingsService,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.handleRoleBasedData();
    this.handleTasksStream();
    this.getNotifications();
  }

  handlePageChange(page: number) {
    this.updateFilters({
      page: page,
    });
  }

  handlePerPageChange(perPage: number) {
    this.updateFilters({
      page: this.tableConf.pagination.page,
      pageSize: perPage,
    });
  }

  handleSelectorChange(status: TaskRead.InternalStatusEnum) {
    this.updateFilters({
      internalStatus: status,
    });
  }

  updateFilters(filters: TasksListRequestParams = {}) {
    this.filterParams$.next({ ...this.filterParams$.value, ...filters });
  }

  changeSearch(searchParam: string) {
    this.updateFilters({
      search: searchParam,
      page: 1,
    });
  }

  viewUserTask(task: DetailTaskRead) {
    this.router.navigateByUrl(`task-manager/${task.id}`);
  }

  private handleRoleBasedData() {
    this.setFiltersBasedOnRole();
    this.getCurrentTrainings();
    this.getReport();
  }

  private setFiltersBasedOnRole() {
    const currentUserId = this.store.selectSnapshot(
      ActiveUserState.currentUser,
    )?.id;
    const getFilters = (): Partial<TasksListRequestParams> => {
      switch (this.role) {
        case User.RoleEnum.Supervisor:
          return { createdBy: currentUserId };
        case User.RoleEnum.User:
        case User.RoleEnum.Employee:
          return { assignee: currentUserId };
        default:
          return {};
      }
    };
    this.updateFilters(getFilters());
  }

  private handleTasksStream() {
    this.tasks$ = this.filterParams$.pipe(
      tap(() => (this.loadingTasks = true)),
      concatMap((filters) => this.taskService.getTasks(filters)),
      tap((tdata) => {
        this.tableConf.pagination.itemsCount = tdata.count;

        this.loadingTasks = false;
      }),
      map((res) => res.results),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private getNotifications() {
    this.notifications$ = this.apiService
      .getTrainingNotifications()
      .pipe(map((r) => r.results));
  }

  private getCurrentTrainings() {
    if (
      this.role &&
      [User.RoleEnum.User, User.RoleEnum.Employee].includes(this.role)
    ) {
      this.loadingTrainings = true;
      this.activeTrainings$ = this.apiService
        .getTrainings({ assignee: this.currentUserId, status: 'active' })
        .pipe(
          finalize(() => (this.loadingTrainings = false)),
          takeUntilDestroyed(this.destroyRef),
        );
    }
  }

  private getReport() {
    this.stats = { ...STATS_MODEL };
    let data$;

    if (
      this.role &&
      [User.RoleEnum.User, User.RoleEnum.Employee].includes(this.role)
    ) {
      data$ = this.apiService.getUserReport();
    } else {
      data$ = this.apiService.getCompanyReport();
    }
    data$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
      this.mapStats(res);
    });
  }

  private mapStats(res: any) {
    Object.keys(res).forEach((companyName) => {
      this.stats.failed += res[companyName].failures;
      this.stats.completed += res[companyName].passes;
      this.stats.usersTotal += res[companyName].total_participants;
      this.stats.notStarted += Math.max(
        res[companyName].total_participants -
          res[companyName].total_completions,
        0,
      );
      this.stats.certificates += res[companyName].passes;
      this.stats.timeSpent += res[companyName].time_spent;
      this.stats.attempts += res[companyName].total_attempts;
    });
    this.setChartData();
  }
  private setChartData() {
    if (this.chart.title) {
      Object.assign(this.chart.title, {
        text: `Users<br><b>${this.stats.usersTotal}</b>`,
      });
    }

    this.chart.series?.push({
      type: 'pie',
      name: 'Users',
      innerSize: '70%',
      data: [
        ['Completed', this.stats?.completed],
        ['Not Started', this.stats?.notStarted],
        ['Failed', this.stats?.failed],
      ],
    });
  }
}
