import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cat-ai-participants-management-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './participants-management-view.component.html',
})
export class ParticipantsManagementViewComponent {}
