import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  DateFormatPipe,
  DateFormatType,
  UrlPrefixPipe,
} from '@cat-ai-us-fe/shared/util';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { PublicTraining } from '@cat-ai-us-fe/api';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'cat-ai-trainings-list',
  standalone: true,
  imports: [
    MatIconModule,
    DateFormatPipe,
    MatProgressSpinnerModule,
    InfiniteScrollDirective,
    RouterLink,
    NgTemplateOutlet,
    NgStyle,
    AsyncPipe,
    UrlPrefixPipe,
  ],
  templateUrl: './trainings-list.component.html',
})
export class TrainingsListComponent {
  @Input() data!: PublicTraining[] | null;
  @Input() loading = false;
  @Input() horizontalView = false;
  @Input() wrapperClasses = '';
  @Input() maxWidth!: number;
  @Output() scrollDown = new EventEmitter();
  readonly dateFormat = DateFormatType.DayMonthYearLinearShort;
}
