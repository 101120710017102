import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MaterialRead } from '@cat-ai-us-fe/api';
import { TrainingFormType } from '@cat-ai-us-fe/trainings/util';

@Component({
  selector: 'cat-ai-training-form',
  standalone: true,
  imports: [FormlyModule, ReactiveFormsModule],
  templateUrl: './training-form.component.html',
})
export class TrainingFormComponent implements OnChanges {
  @Output() modelChanged = new EventEmitter<TrainingFormType>();
  @Input() materials!: MaterialRead[] | null;
  @Input() config!: FormlyFieldConfig[];
  @Input() model!: TrainingFormType;
  formGroup = new FormGroup({});

  get validForm() {
    return this.formGroup.valid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.formGroup.patchValue(changes.model.currentValue);
    }
  }

  public markAsTouched() {
    this.formGroup.markAllAsTouched();
  }

  onModelChange(data: TrainingFormType) {
    this.modelChanged.next(data);
  }
}
