import { Injectable } from '@angular/core';
import {
  TasksListRequestParams,
  TasksService as TasksServiceApi,
} from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(private apiService: TasksServiceApi) {}

  getTasks(filters: TasksListRequestParams = {}) {
    return this.apiService.tasksList(filters);
  }

  getTask(id: number) {
    return this.apiService.tasksRetrieve({ id });
  }
}
