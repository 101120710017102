import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SelectorComponent } from '@cat-ai-us-fe/shared/ui';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PrivateAnswerVariant, PrivateQuestion } from '@cat-ai-us-fe/api';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cat-ai-question-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SelectorComponent,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    NgTemplateOutlet,
  ],
  templateUrl: './question-form.component.html',
})
export class QuestionFormComponent implements OnChanges, OnInit {
  @Input() index!: number;
  @Input() loadingState!: boolean;
  @Input() question!: PrivateQuestion | Partial<PrivateQuestion>;
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter<Partial<PrivateQuestion>>();
  private destroyRef = inject(DestroyRef);

  form = new FormGroup({
    text: new FormControl('', Validators.required),
    single: new FormControl(true, Validators.required),
    variants: new FormControl(),
    score: new FormControl(0, Validators.required),
  });
  questionTypeOptions = [
    { name: 'Single select', id: true },
    { name: 'Multi select', id: false },
  ];
  questionSceleton: Partial<PrivateQuestion> = {
    text: '',
    variants: [
      { id: 0, text: '' },
      { id: 0, text: '' },
      { id: 0, text: '' },
    ],
  };

  get variantsValue(): PrivateAnswerVariant[] {
    return this.form.controls.variants.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question?.firstChange) {
      this.form.patchValue(this.question);
    }
    if (this.loadingState) {
      this.form.patchValue(this.questionSceleton);
    }
  }

  ngOnInit(): void {
    this.subscribeToFormUpdates();
  }

  changeCorrentVariant(variantIndex: number) {
    this.variantsValue[variantIndex].correct = true;
    this.form.controls.variants.setValue(this.variantsValue);
  }

  changeVariantText(variantIndex: number, text?: string) {
    this.variantsValue[variantIndex].text = text || '';
    this.form.controls.variants.setValue(this.variantsValue);
  }

  addOption() {
    this.form.controls.variants.patchValue([
      ...this.variantsValue,
      {
        text: '',
        correct: false,
      },
    ]);
  }

  removeOption(index: number) {
    this.variantsValue.splice(index, 1);
    this.form.controls.variants.setValue(this.variantsValue);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
  }

  private subscribeToFormUpdates() {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.update.emit(this.form.value as Partial<PrivateQuestion>);
      });
  }
}
