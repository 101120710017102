<div class="min-h-screen relative">
  <div #navHeader class="fixed top-0 right-0 left-0 bg-gray-50">
    <div class="bg-white flex items-center border-b border-gray-200 p-6">
      <mat-icon class="mr-4 cursor-pointer" (click)="closeQuiz.emit()"
        >close</mat-icon
      >
      <h1>{{ training?.name }} - Quiz</h1>
      <button
        mat-flat-button
        color="accent"
        class="ml-auto"
        (click)="finishAttempt.emit()"
      >
        Finish Attempt
      </button>
    </div>
    <!-- QUIZ NAVIGATION -->
    <div class="bg-white rounded-m shadow-sm p-4 w-[800px] m-auto mt-12">
      <span class="flex justify-between">
        <p class="text-gray-700">Quiz Navigation</p>
        <span class="flex gap-2">
          <p class="text-gray-500">Time left</p>
          <p>{{ countdown$ | async }}</p>
        </span>
      </span>
      <cat-ai-quiz-navigation
        [questions]="training?.questions"
        wrapperClasses="place-content-center mt-3"
        [uniqueId]="uniqueId"
      ></cat-ai-quiz-navigation>
    </div>
  </div>

  <div
    class="w-[800px] m-auto mb-8 flex flex-col gap-2.5 pt-4"
    [style.margin-top]="navHeader.clientHeight + 'px'"
  >
    <!-- QUIZ QUESTION -->
    @for (question of training?.questions; track question?.id) {
      <cat-ai-pass-quiz-question-form
        [question]="question"
        [index]="$index"
        [uniqueId]="uniqueId"
        (setAnswer)="updateQuestionAnswer($event, question.id)"
      ></cat-ai-pass-quiz-question-form>
    }
  </div>
</div>
