import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { PrivateAnswerVariant, PrivateQuestion } from '@cat-ai-us-fe/api';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cat-ai-pass-quiz-question-form',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './pass-quiz-question-form.component.html',
})
export class PassQuizQuestionFormComponent implements OnInit {
  @Input() question!: PrivateQuestion;
  @Input() index!: number;
  @Input() uniqueId = '';
  @Output() setAnswer = new EventEmitter();
  selectedOptions$ = new BehaviorSubject<number[] | null>(null);
  destroyRef = inject(DestroyRef);

  get selectedOptionsValue() {
    return this.selectedOptions$.value || [];
  }

  ngOnInit(): void {
    this.selectedOptions$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (!value) {
          return;
        }
        this.setAnswer.emit(value);
      });
  }

  chooseOption(
    variant: PrivateAnswerVariant | any,
    state: { selected: boolean; id: number },
  ) {
    if (state.selected) {
      const res = this.selectedOptionsValue.filter((id) => id !== state.id);
      this.selectedOptions$.next([...res]);
      state.selected = false;
      return;
    }
    if (this.question.single) {
      this.selectedOptions$.next([variant.id]);
    } else {
      if (!this.selectedOptionsValue.find((id) => state.id === id)) {
        this.selectedOptions$.next([...this.selectedOptionsValue, variant.id]);
      }
    }
    state.selected = !!this.selectedOptionsValue.find((id) => state.id === id);
  }
}
