import { TrainingParticipants } from '@cat-ai-us-fe/api';
import {
  ColumnConfig,
  SecondsToTimePipe,
  TableConfig,
} from '@cat-ai-us-fe/shared/util';

const secondsToTimePipe = new SecondsToTimePipe();

const REPORTS_TABLE_COLUMNS: ColumnConfig<TrainingParticipants>[] = [
  {
    label: 'USER',
    html: (
      data,
    ) => `<p class="font-bold">${data.first_name} ${data.last_name}</p>
    <p class="text-gray-500">${data.email}</p>`,
  },
  {
    label: 'JOB CATEGORY',
    html: (row) => (row.job_category && row.job_category.name) || '-',
  },
  {
    label: 'HIGHEST SCORE',
    key: 'highest_score',
  },
  {
    label: 'QUIZ ATTEMPTS',
    key: 'quiz_attempts',
  },
  {
    label: 'AVG. QUIZ TIME',
    html: (row) =>
      row.average_quiz_time
        ? secondsToTimePipe.transform(row.average_quiz_time, true)
        : ('-' as any),
  },
  {
    label: 'ERROR RATE',
    html: (row) => `${row.error_rate}%`,
  },
  {
    label: 'EFFECTIVITY',
    key: 'efficiency',
  },
];

export const REPORTS_TABLE_CONFIG: TableConfig<TrainingParticipants> = {
  columns: REPORTS_TABLE_COLUMNS,
  pagination: {
    page: 1,
    perPage: 10,
    itemsCount: 1,
  },
  actions: {
    viewDocument: true,
  },
};
