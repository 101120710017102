<div class="p-6 flex flex-col gap-4">
  <div class="flex gap-4">
    <div
      #chartEl
      class="p-4 h-fit bg-white rounded-lg shadow-sm flex flex-col gap-4"
    >
      <h4 *catAiSupervisorAccess>Training Statistic</h4>

      <div class="flex flex-col gap-4 min-h-fit">
        <div
          class="flex items-center bg-gray-25 rounded-sm border border-gray-200 max-w-full"
          [class.flex-col]="role === RoleEnum.Supervisor"
        >
          <div class="highcharts-wrap">
            @if (chart.series?.length) {
              <highcharts-chart [Highcharts]="Highcharts" [options]="chart">
              </highcharts-chart>
            }
          </div>

          <div
            class="w-fit h-fit flex flex-row gap-4 justify-center text-xs p-4"
            [class.flex-col]="role !== RoleEnum.Supervisor"
          >
            <div class="flex flex-row items-center gap-2">
              <div class="bg-green-500 h-4 w-4 rounded-xs"></div>
              <p>Completed</p>
              <p class="font-bold text-sm">{{ stats.completed }}</p>
            </div>
            <div class="flex flex-row items-center gap-2">
              <div class="bg-black h-4 w-4 rounded-xs"></div>
              <p class="text-nowrap">Not Started</p>
              <p class="font-bold text-sm">{{ stats.notStarted }}</p>
            </div>
            <div class="flex flex-row items-center gap-2">
              <div class="bg-red-500 h-4 w-4 rounded-xs"></div>
              <p>Failed</p>
              <p class="font-bold text-sm">{{ stats.failed }}</p>
            </div>
          </div>
        </div>
        <div *catAiSupervisorAccess class="flex flex-row">
          <div
            class="p-4 flex flex-row items-start justify-start bg-gray-25 border border-gray-200 rounded-sm min-h-full w-full gap-3"
          >
            <div class="flex-auto">
              <p class="text-gray-900 pb-4 text-sm">Total time spend</p>
              <span class="flex gap-1.5">
                <mat-icon class="material-icons-outlined">timer</mat-icon>
                <h1>{{ stats.timeSpent | secondsToTime }}</h1>
              </span>
            </div>

            <div class="w-px h-full bg-gray-200 flex-nowrap"></div>

            <div class="flex-1">
              <p class="text-gray-900 text-sm pb-4">Certificates</p>
              <span class="flex gap-1.5">
                <mat-icon class="material-icons-outlined"
                  >workspace_premium</mat-icon
                >
                <h1>{{ stats.certificates }}</h1>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *catAiSupervisorAccess class="flex-grow">
      <cat-ai-training-notifications
        [maxHeight]="chartEl.clientHeight"
        [notifications]="notifications$ | async"
      ></cat-ai-training-notifications>
    </div>
    <div
      *catAiRoleAccess="[RoleEnum.User]"
      class="shadow-sm p-4 bg-white rounded-lg flex-grow"
    >
      <div
        class="p-4 flex flex-row justify-start bg-gray-25 border border-gray-200 rounded-sm min-h-full w-full gap-3"
      >
        <div
          class="flex-auto border-r border-gray-200 flex flex-col justify-between py-3"
        >
          <p class="text-gray-900 pb-4 text-sm">Total time spend</p>
          <span class="flex gap-1.5">
            <mat-icon class="material-icons-outlined">timer</mat-icon>
            <h1>{{ stats.timeSpent | secondsToTime }}</h1>
          </span>
        </div>

        <div
          class="flex-auto border-r border-gray-200 flex flex-col justify-between py-3"
        >
          <p class="text-gray-900 pb-4 text-sm">Total Quiz Taken</p>
          <span class="flex gap-1.5">
            <mat-icon class="material-icons-outlined">photo_album</mat-icon>
            <h1>{{ stats.attempts }}</h1>
          </span>
        </div>

        <div class="flex-1 flex flex-col justify-between py-3">
          <p class="text-gray-900 text-sm pb-4">Certificates</p>
          <span class="flex gap-1.5">
            <mat-icon class="material-icons-outlined"
              >workspace_premium</mat-icon
            >
            <h1>{{ stats.certificates }}</h1>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    *catAiRoleAccess="[RoleEnum.Employee, RoleEnum.User]"
    #activeTrainingsEl
    class="bg-white rounded-lg shadow-sm pt-4 flex flex-col flex-grow"
  >
    <h4 class="px-4">Current Trainings</h4>
    <cat-ai-trainings-list
      [horizontalView]="true"
      [data]="activeTrainings$ | async"
      [loading]="loadingTrainings"
      [maxWidth]="activeTrainingsEl.clientWidth"
      wrapperClasses="p-6"
    ></cat-ai-trainings-list>
  </div>

  <!-- TASKS TABLE -->
  <div class="bg-white rounded-lg shadow-sm">
    <div class="flex flex-row justify-between items-center px-6 py-5">
      <h4 *catAiSupervisorAccess>Users Tasks</h4>
      <h4 *catAiRoleAccess="[RoleEnum.User, RoleEnum.Employee]">Tasks</h4>
      <div class="flex flex-row gap-3">
        <cat-ai-selector
          label="Select Status"
          [enumOptions]="TaskInternalStatuses | enumToOptions"
          [resetOption]="true"
          (change)="handleSelectorChange($event)"
          wrapperClasses="shadow-xs w-52"
        ></cat-ai-selector>
        <cat-ai-search
          wrapperClasses="!min-w-52 shadow-xs"
          (search)="changeSearch($event)"
        ></cat-ai-search>
      </div>
    </div>
    <cat-ai-table
      tableClasses="!table-auto"
      [rows]="tasks$ | async"
      [config]="tableConf"
      [isLoading]="loadingTasks"
      (pageChange)="handlePageChange($event)"
      (perPageChange)="handlePerPageChange($event)"
      (search)="viewUserTask($event)"
    ></cat-ai-table>
  </div>
</div>
