import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SearchComponent, TableComponent } from '@cat-ai-us-fe/shared/ui';
import { TABLE_CONFIG } from './config';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject, Observable, combineLatest, map, tap } from 'rxjs';
import {
  CompanyGroup,
  JobCategory,
  Participant,
  TrainingsParticipantsListRequestParams,
  User,
} from '@cat-ai-us-fe/api';
import { AddParticipantsModalComponent } from '../../modals/add-participants-modal/add-participants-modal.component';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { SelectorComponent } from '@cat-ai-us-fe/shared/ui';
@Component({
  selector: 'cat-ai-training-participants',
  standalone: true,
  imports: [
    MatButtonModule,
    SearchComponent,
    TableComponent,
    AsyncPipe,
    SelectorComponent,
  ],
  templateUrl: './training-participants.component.html',
})
export class TrainingParticipantsComponent implements OnInit, OnChanges {
  @Output() patricipantsChange = new EventEmitter();
  @Input() trainingId!: number;
  config = TABLE_CONFIG;
  data$!: Observable<User[] | Participant[]>;
  departments$!: Observable<CompanyGroup[]>;
  jobCategories$!: Observable<JobCategory[]>;
  destroyRef = inject(DestroyRef);
  loading = false;
  private selectedUsers$ = new BehaviorSubject<(User | Participant)[]>([]);
  private filterParams$ =
    new BehaviorSubject<TrainingsParticipantsListRequestParams>({} as any);

  constructor(
    private dialog: MatDialog,
    private apiService: TrainingsService,
  ) {}

  ngOnChanges(): void {
    if (this.trainingId) {
      this.setExistingParticipants();
    }
  }

  ngOnInit(): void {
    this.handleLocalData();
    this.getSelectorsData();
    this.subscribeOnSelection();
  }

  private subscribeOnSelection() {
    this.selectedUsers$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.patricipantsChange.emit(val);
      });
  }

  filterBySearch(param: string) {
    this.updateFilters({ search: param, page: 1 });
  }

  openParticipantsModal() {
    this.dialog
      .open(AddParticipantsModalComponent, {
        height: '800px',
        minWidth: '1040px',
        data: {
          trainingId: this.trainingId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedUsers) => this.selectedUsers$.next(selectedUsers));
  }

  removeSelection(user: User | Participant) {
    if (this.trainingId) {
      this.apiService
        .removeTrainingParticipants(this.trainingId, { ids: [user.id] })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
    const selections = [...this.selectedUsers$.value];
    const index = selections.indexOf(user);
    selections.splice(index, 1);
    this.selectedUsers$.next(selections);
  }

  changeDepartment(id: number) {
    this.updateFilters({ companyId: id });
  }

  changeJobCategory(id: number) {
    this.updateFilters({ jobCategory: id });
  }

  private updateFilters(
    filters: Partial<TrainingsParticipantsListRequestParams> = {},
  ) {
    this.filterParams$.next({ ...this.filterParams$.value, ...filters });
  }

  private handleLocalData() {
    const updates$ = combineLatest([this.filterParams$, this.selectedUsers$]);
    this.data$ = updates$.pipe(
      map(([filters, users]) =>
        this.filterSelectedUsers(filters, users as User[]),
      ),
      tap((res) => (this.config.pagination.itemsCount = res.length)),
    );
  }

  private getSelectorsData() {
    this.jobCategories$ = this.apiService.getJobCategories();
    this.departments$ = this.apiService.getDepartments();
  }

  private setExistingParticipants() {
    this.apiService
      .getParticipants({
        trainingId: this.trainingId as any,
        pageSize: 10000000,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.selectedUsers$.next(res.results);
        this.config.pagination.itemsCount = res.count;
      });
  }

  private filterSelectedUsers(filters: any, users: User[] = []) {
    let filtered = [];

    filtered = users.filter((user: any) => {
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(user, key)) {
          if (user[key] && user[key].id && filters[key]) {
            return user[key].id !== filters[key];
          }
        }
      }
      return true;
    });

    if (filters.search) {
      filtered = filtered.filter((user) =>
        user.fullname
          .toLocaleLowerCase()
          .match(filters.search.toLocaleLowerCase()),
      );
    }

    return filtered;
  }
}
