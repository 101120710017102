import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UrlPrefixPipe } from '@cat-ai-us-fe/shared/util';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';

@Component({
  selector: 'cat-ai-training-details',
  standalone: true,
  imports: [DatePipe, UrlPrefixPipe],
  templateUrl: './training-details.component.html',
})
export class TrainingDetailsComponent {
  @Input() training!: SupervisorTraining | null;
}
