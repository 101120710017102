<div class="flex flex-col gap-6 p-6">
  <div class="flex justify-between items-baseline">
    <h2>Quiz</h2>
    <button mat-flat-button color="accent" (click)="create()">
      Create Quiz
    </button>
  </div>
  @if (questions && questions.length) {
    <cat-ai-collapsible-card wrapperClasses="bg-gray-25 shadow-xs">
      <div header class="flex items-center">
        <h4>Quiz</h4>
        <button mat-button color="accent" (click)="edit($event)">
          <mat-icon>mode_edit</mat-icon>
          Edit
        </button>
      </div>
      <cat-ai-questions-list [questions]="questions"></cat-ai-questions-list>
      <button mat-button color="warn" class="self-end" (click)="delete()">
        <mat-icon>delete_outline</mat-icon>
        Delete
      </button>
    </cat-ai-collapsible-card>
  }
</div>
