import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PublicTraining, TrainingsListRequestParams } from '@cat-ai-us-fe/api';
import { ActiveUserState } from '@cat-ai-us-fe/shared/data-access';
import { SearchComponent } from '@cat-ai-us-fe/shared/ui';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { TrainingsListComponent } from '@cat-ai-us-fe/trainings/ui';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  of,
  scan,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'cat-ai-personal-trainings-view',
  standalone: true,
  imports: [SearchComponent, TrainingsListComponent, AsyncPipe],
  providers: [AsyncPipe],
  templateUrl: './personal-trainings-view.component.html',
})
export class PersonalTrainingsViewComponent implements OnInit {
  loading = false;
  data$!: Observable<PublicTraining[]>;
  pagination: Partial<TrainingsListRequestParams> = {
    pageSize: 10,
    page: 1,
  };

  private destroyRef = inject(DestroyRef);
  private apiService = inject(TrainingsService);
  private store = inject(Store);
  private currentUserId = this.store.selectSnapshot(ActiveUserState.currentUser)
    .id;
  private filterParams$ = new BehaviorSubject<TrainingsListRequestParams>({
    ...this.pagination,
    assignee: this.currentUserId,
  });

  ngOnInit() {
    this.handleDataStream();
  }

  onScrollDown() {
    if (this.loading) {
      return;
    }
    if (this.pagination.page) {
      this.pagination.page += 1;
    }
    this.updateFilters(this.pagination);
  }

  updateSearch(searchParam: string) {
    this.updateFilters({ search: searchParam, page: 1 });
  }

  updateFilters(filters: TrainingsListRequestParams = {}) {
    this.filterParams$.next({ ...this.filterParams$.value, ...filters });
  }

  private handleDataStream() {
    this.loading = true;
    this.data$ = this.filterParams$.pipe(
      tap(() => (this.loading = true)),
      switchMap((filters) => {
        return this.apiService.getTrainings(filters).pipe(
          catchError(() => {
            return of([]);
          }),
          finalize(() => (this.loading = false)),
          shareReplay(1),
          takeUntilDestroyed(this.destroyRef),
        );
      }),
      scan((list: PublicTraining[], items: PublicTraining[]) => {
        if (this.pagination.page === 1) {
          return items;
        } else {
          return [...list, ...items];
        }
      }, []),
    );
  }
}
