<div class="rounded-m shadow-sm bg-white p-1 !m-1">
  <div class="py-4 px-6 flex-wrap flex-row gap-2">
    <div class="flex justify-between items-center">
      <div class="flex gap-2">
        <cat-ai-search
          wrapperClasses="w-[300px]"
          (search)="updateFilters.emit({ search: $event, page: 1 })"
        ></cat-ai-search>
        @if (departments) {
          <cat-ai-selector
            label="Department"
            [options]="departments"
            (change)="updateFilters.emit({ groups: [$event] })"
            [resetOption]="true"
            wrapperClasses="shadow-xs"
            class="w-[200px]"
          ></cat-ai-selector>
        }
        @if (jobCategories) {
          <cat-ai-selector
            label="Job category"
            [options]="jobCategories"
            (change)="updateFilters.emit({ jobCategories: [$event] })"
            [resetOption]="true"
            wrapperClasses="shadow-xs"
            class="w-[200px]"
          ></cat-ai-selector>
        }
      </div>
    </div>
  </div>
  <cat-ai-table
    [rows]="data"
    [config]="tableConf"
    [isLoading]="loading"
    (pageChange)="updateFilters.emit({ page: $event })"
    (perPageChange)="updateFilters.emit({ pageSize: $event, page: 1 })"
    (viewDocument)="openReport.emit($event)"
    (search)="openGroupMembers.emit($event)"
  ></cat-ai-table>
</div>
