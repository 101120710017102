import { User } from '@cat-ai-us-fe/api';
import { ColumnConfig, TableConfig } from '@cat-ai-us-fe/shared/util';

const columns: ColumnConfig<User>[] = [
  {
    label: 'Name',
    html: (row) =>
      `<p class="font-medium">${row.fullname}</p><p>${row.email}</p>`,
  },
  {
    label: 'Job category',
    html: (row) => row.job_category?.name,
  },
  {
    label: 'Departament',
    html: (row) => row.company?.name,
  },
];

export const TABLE_CONFIG: TableConfig<User> = {
  columns: columns,
  pagination: {
    page: 1,
    perPage: 7,
  },
  selectionEnabled: true,
};
