import { TitleCasePipe } from '@angular/common';
import {
  MaterialRead,
  Participant,
  TrainingGroup,
  TrainingParticipants,
} from '@cat-ai-us-fe/api';
import { ChipComponent } from '@cat-ai-us-fe/shared/ui';
import {
  ColumnConfig,
  DateFormatType,
  TableConfig,
  transformDate,
} from '@cat-ai-us-fe/shared/util';
import * as Highcharts from 'highcharts';

const titleCasePipe = new TitleCasePipe();

export const chartOptions: Highcharts.Options = {
  chart: {
    plotBackgroundColor: '',
    plotBorderWidth: 0,
    plotShadow: false,
    backgroundColor: '',
  },
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    y: 40,
    style: {
      fontSize: '20px',
      fontWeight: 'normal',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      borderWidth: 5,
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '100%'],
      size: '200%',
    },
  },
  colors: ['#12B76A', '#528BFF', '#202939', '#F04438'],
  series: [],
  credits: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const PARTICIPANTS_TABLE_COLUMNS: ColumnConfig<Participant>[] = [
  {
    label: 'USER',
    html: (
      data,
    ) => `<p class="font-bold">${data.first_name} ${data.last_name}</p>
    <p class="text-gray-500">${data.email}</p>`,
  },
  {
    label: 'JOB CATEGORY',
    html: (row) => (row.job_category && row.job_category.name) || '-',
  },
  {
    label: 'DEPARTAMENT',
    html: (row) => row.company?.name,
  },
  {
    html: (data) =>
      transformDate(data.task.start_date, DateFormatType.MonthDateYearAlt),
    label: 'START DATE',
  },
  {
    html: (data) =>
      transformDate(data.task.end_date, DateFormatType.MonthDateYearAlt),
    label: 'DUE DATE',
  },
  {
    html: (data) => titleCasePipe.transform(data.task.external_status) || '',
    label: 'Status',
  },
];

export const PARTICIPANTS_TABLE_CONFIG: TableConfig<Participant> = {
  columns: PARTICIPANTS_TABLE_COLUMNS,
  pagination: {
    page: 1,
    perPage: 6,
    itemsCount: 1,
  },
  actions: {
    search: true,
  },
};

const GROUP_REPORTS_TABLE_COLUMNS: ColumnConfig<TrainingGroup>[] = [
  {
    label: 'DEPARTAMENT',
    key: 'name',
  },
  {
    label: 'count users',
    key: 'users_count',
  },
  {
    label: 'Status',
    componentRef: ChipComponent,
    inputs: {
      color: 'gray',
      bindedProperty: 'status',
      colorsConfig: {
        [MaterialRead.StatusEnum.InProgress]: 'blue',
        passed: 'green',
        failed: 'red',
        completed: 'green',
      },
    },
  },
  {
    label: 'DATE START',
    html: (data) =>
      data.start
        ? transformDate(data.start, DateFormatType.MonthDateYearAlt)
        : '-',
  },
  {
    label: 'training effectivity',
    key: 'efficency',
  },
];

export const GROUP_REPORTS_TABLE_CONFIG: TableConfig<TrainingGroup> = {
  columns: GROUP_REPORTS_TABLE_COLUMNS,
  pagination: {
    page: 1,
    perPage: 10,
    itemsCount: 1,
  },
  selectionEnabled: true,
  searchActionIcon: 'people',
  actions: {
    search: true,
    viewDocument: true,
  },
};

const PARTICIPANTS_REPORTS_TABLE_COLUMNS: ColumnConfig<TrainingParticipants>[] =
  [
    {
      label: 'USER',
      html: (
        data,
      ) => `<p class="font-bold">${data.first_name} ${data.last_name}</p>
    <p class="text-gray-500">${data.email}</p>`,
    },
    {
      label: 'Status',
      componentRef: ChipComponent,
      inputs: {
        color: 'gray',
        bindedProperty: 'status',
        colorsConfig: {
          [MaterialRead.StatusEnum.InProgress]: 'blue',
          passed: 'green',
          failed: 'red',
          completed: 'green',
        },
      },
    },
    {
      label: 'DATE START',
      html: (row) =>
        row.start
          ? transformDate(row.start, DateFormatType.MonthDateYearAlt)
          : '-',
    },
    {
      label: 'training effectivity',
      key: 'efficiency',
    },
  ];

export const PARTICIPANTS_REPORTS_TABLE_CONFIG: TableConfig<TrainingParticipants> =
  {
    columns: PARTICIPANTS_REPORTS_TABLE_COLUMNS,
    pagination: {
      page: 1,
      perPage: 10,
      itemsCount: 1,
    },
    selectionEnabled: true,
    actions: {
      viewDocument: true,
    },
  };
