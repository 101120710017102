<div
  mat-dialog-header
  class="flex justify-between gap-3 px-6 py-5 items-center"
>
  <h2>Participants</h2>
  <mat-icon class="material-icons-outlined" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content>
  <div class="flex mb-6 gap-4">
    <cat-ai-search
      (search)="updateSearch($event)"
      wrapperClasses="shadow-xs"
    ></cat-ai-search>
    <cat-ai-selector
      label="Select department"
      (change)="changeDepartament($event)"
      wrapperClasses="shadow-xs"
    ></cat-ai-selector>
    <cat-ai-selector
      label="Select job category"
      (change)="changeCategory($event)"
      wrapperClasses="shadow-xs"
    ></cat-ai-selector>
  </div>
  <cat-ai-table
    [config]="config"
    [rows]="data$ | async"
    (selectedRowsChange)="setSelections($event)"
    (pageChange)="changePage($event)"
    [isLoading]="loading"
    tableClasses="!table-auto periodic-tasks-list"
  ></cat-ai-table>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-flat-button mat-dialog-close color="basic">Cancel</button>
  <button
    mat-flat-button
    color="accent"
    [mat-dialog-close]="selectedParticipants"
  >
    Add ({{ selectedParticipants && selectedParticipants.length }})
  </button>
</mat-dialog-actions>
