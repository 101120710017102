import { TrainingGroup, TrainingParticipants } from '@cat-ai-us-fe/api';
import {
  ColumnConfig,
  SecondsToTimePipe,
  TableConfig,
} from '@cat-ai-us-fe/shared/util';

const secondsToTimePipe = new SecondsToTimePipe();

const GROUP_TABLE_COLUMNS: ColumnConfig<TrainingGroup | any>[] = [
  {
    label: 'HIGHEST SCORE',
    key: 'highest_score',
  },
  {
    label: 'Quiz difficulty',
    key: 'quiz_difficulty',
  },
  {
    label: 'AVG. QUIZ TIME',
    html: (row: any) =>
      row.average_quiz_time
        ? secondsToTimePipe.transform(row.average_quiz_time, true)
        : ('-' as any),
  },
  {
    label: 'ERROR RATE',
    html: (row) => `${row.error_rate}%`,
  },
];

export const GROUP_TABLE_CONFIG: TableConfig<TrainingGroup | any> = {
  columns: GROUP_TABLE_COLUMNS,
  pagination: {
    page: 1,
    perPage: 9999,
    itemsCount: 1,
  },
  paginationDisabled: true,
};

const GROUP_PARTICIPANTS_TABLE_COLUMNS: ColumnConfig<TrainingParticipants>[] = [
  {
    label: 'USER',
    html: (
      data,
    ) => `<p class="font-bold">${data.first_name} ${data.last_name}</p>
    <p class="text-gray-500">${data.email}</p>`,
  },
  {
    label: 'HIGHEST SCORE',
    key: 'highest_score',
  },

  {
    label: 'QUIZ ATTEMPTS',
    key: 'quiz_attempts',
  },

  {
    label: 'AVG. QUIZ TIME',
    html: (row) =>
      row.average_quiz_time
        ? secondsToTimePipe.transform(row.average_quiz_time, true)
        : ('-' as any),
  },
  {
    label: 'ERROR RATE',
    html: (row) => `${row.error_rate}%`,
  },
];

export const GROUP_PARTICIPANTS_TABLE_CONFIG: TableConfig<TrainingParticipants> =
  {
    columns: GROUP_PARTICIPANTS_TABLE_COLUMNS,
    pagination: {
      page: 1,
      perPage: 9999,
      itemsCount: 1,
    },
    paginationDisabled: true,
  };

const PARTICIPANT_TABLE_COLUMNS: ColumnConfig<TrainingParticipants>[] = [
  {
    label: 'HIGHEST SCORE',
    key: 'highest_score',
  },

  {
    label: 'QUIZ ATTEMPTS',
    key: 'quiz_attempts',
  },

  {
    label: 'AVG. QUIZ TIME',
    html: (row) =>
      row.average_quiz_time
        ? secondsToTimePipe.transform(row.average_quiz_time, true)
        : ('-' as any),
  },
  {
    label: 'ERROR RATE',
    html: (row) => `${row.error_rate || 0}%`,
  },
];

export const PARTICIPANT_TABLE_CONFIG: TableConfig<TrainingParticipants> = {
  columns: PARTICIPANT_TABLE_COLUMNS,
  pagination: {
    page: 1,
    perPage: 9999,
    itemsCount: 1,
  },
  paginationDisabled: true,
};
