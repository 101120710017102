<div class="flex flex-col gap-6 p-6">
  <div class="mt-1.5">
    <h2>Training name</h2>
  </div>
  @if (config && model) {
    <formly-form
      [form]="formGroup"
      [fields]="config"
      [model]="model"
      (modelChange)="onModelChange($event)"
      class="[&>formly-field>formly-group]:grid [&>formly-field>formly-group]:gap-4 [&>formly-field>formly-group]:grid-cols-5"
    ></formly-form>
  }
</div>
