<div class="flex gap-1.5 flex-wrap {{ wrapperClasses }}">
  @for (question of questions; track $index) {
    <div
      class="flex justify-center items-center bg-grey-50 rounded-xs border border-gray-100 h-8 cursor-pointer
      {{ showAnswers ? 'px-2 bg-gray-50' : 'aspect-square' }}"
      (click)="scrollToQuestion(question.id)"
      id="navigation-block-question-{{ question.id }}"
    >
      <p class="leading-3">{{ $index + 1 }}</p>
      @if (showAnswers) {
        @if (question.hasCorrectAnswer) {
          <span class="bg-success-500 h-2 w-2 rounded-full ml-2"></span>
        }
        @if (question.hasUncorrentAnswer) {
          <span class="bg-error h-2 w-2 rounded-full ml-2"></span>
        }
      }
    </div>
  }
</div>
