<div>
  @if (training$ | async; as training) {
    @if (loading) {
      <div class="h-screen flex items-center justify-center">
        <mat-spinner [strokeWidth]="3" [diameter]="50"></mat-spinner>
      </div>
    } @else {
      <mat-tab-group
        class="header-less-tabs"
        [(selectedIndex)]="selectedView"
        [preserveContent]="false"
        animationDuration="0"
      >
        <mat-tab>
          <cat-ai-pass-quiz-intro
            [training]="training"
            (startQuiz)="startQuiz()"
          ></cat-ai-pass-quiz-intro>
        </mat-tab>
        <mat-tab>
          <cat-ai-pass-quiz-questions
            [training]="training"
            (answerQuestion)="answerQuestion($event)"
            (closeQuiz)="closeQuiz()"
            (finishAttempt)="finishAttempt(training.questions)"
          ></cat-ai-pass-quiz-questions>
        </mat-tab>
      </mat-tab-group>
    }
  }
</div>
