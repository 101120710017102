import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import {
  TrainingDetailsComponent,
  TrainingReportsTabComponent,
} from '@cat-ai-us-fe/trainings/ui';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import {
  JobCategory,
  TrainingParticipants,
  TrainingsParticipantsReportListRequestParams,
} from '@cat-ai-us-fe/api';
import { REPORTS_TABLE_CONFIG } from './config';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'cat-ai-reports-list-view',
  standalone: true,
  imports: [
    TrainingDetailsComponent,
    AsyncPipe,
    TrainingReportsTabComponent,
    MatIcon,
    RouterLink,
    MatButton,
  ],
  templateUrl: './reports-list-view.component.html',
})
export class ReportsListViewComponent implements OnInit {
  currentTraining$!: Observable<SupervisorTraining>;
  jobCategories$!: Observable<JobCategory[]>;
  reports$!: Observable<TrainingParticipants[]>;
  config = REPORTS_TABLE_CONFIG;
  loading = false;
  private reportsFilters$ = new BehaviorSubject<
    Partial<TrainingsParticipantsReportListRequestParams>
  >({});

  private destroyRef = inject(DestroyRef);
  constructor(
    private apiService: TrainingsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.setCurrentTraining();
    this.setReports();
    this.jobCategories$ = this.apiService.getJobCategories();
  }

  updateReportsFilters(filters: Partial<any> = {}) {
    this.reportsFilters$.next({ ...this.reportsFilters$.value, ...filters });
  }

  gotoMemberReport(row: { id: number }) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`../${row.id}`], {
        relativeTo: this.route,
      }),
    );
    window.open(url, '_blank');
  }

  private getTrainingId() {
    return this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter((id): id is string => !!id),
      map((id) => +id),
    );
  }

  private setCurrentTraining() {
    this.currentTraining$ = this.getTrainingId().pipe(
      switchMap((id) => this.apiService.getTraining(id)),
    );
  }

  private setReports() {
    const groupId$ = this.route.paramMap.pipe(
      map((params) => params.get('groupId')),
      filter((id): id is string => !!id),
      map((id) => +id),
    );
    const updates$ = combineLatest([
      this.getTrainingId(),
      this.reportsFilters$,
      groupId$,
    ]);
    this.reports$ = updates$.pipe(
      tap(() => (this.loading = true)),
      switchMap(([id, filters, groups]) =>
        this.apiService.getParticipantsReport({
          id,
          ...filters,
          groups: [groups],
        }),
      ),
      tap((pdata) => {
        this.config.pagination.itemsCount = pdata.count;
        this.loading = false;
      }),
      map((r) => r.results),
    );
  }
}
