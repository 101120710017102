import { GenerateQuizRequest } from '@cat-ai-us-fe/api';
import { FormlyFieldConfig } from '@ngx-formly/core';

const timeRange = (to = 0, step = 1, from = 0) => {
  return Array.from(Array(Math.floor((to - from) / step) + 1)).map(
    (v, k) => from + k * step,
  );
};

const setTimeOptions = () => {
  const minutesRange = timeRange(60, 5, 5).map((tr) => {
    return {
      label: `${tr}:00 min`,
      value: tr * 60,
    };
  });
  const hoursRange = timeRange(6, 0.5, 1).map((tr) => {
    return {
      label: `${tr} hour`,
      value: tr * 60 * 60,
    };
  });
  return [...minutesRange, ...hoursRange];
};

export const FORM_MODEL: Partial<GenerateQuizRequest> = {
  num_questions: undefined as any,
  num_variants: undefined as any,
  difficulty: 'medium',
  seconds_to_complete: undefined as any,
  prompt: undefined as any,
};

export const FORM_FIELDS: FormlyFieldConfig[] = [
  {
    key: 'num_questions',
    type: 'cat-ai-input',
    props: {
      placeholder: 'Questions quantity',
      label: 'Questions',
      required: true,
    },
    parsers: [(value) => Number(value)],
  },
  {
    key: 'num_variants',
    type: 'cat-ai-input',
    props: {
      placeholder: 'Questions options',
      label: 'Questions options',
      required: true,
    },
    parsers: [(value) => Number(value)],
  },
  {
    key: 'difficulty',
    type: 'cat-ai-select',
    props: {
      label: 'Difficulty',
      required: true,
      options: Object.values(GenerateQuizRequest.DifficultyEnum).map(
        (option) => {
          return { label: option, value: option };
        },
      ),
    },
  },
  {
    key: 'seconds_to_complete',
    type: 'cat-ai-select',
    props: {
      label: 'Time for quiz',
      required: true,
      options: setTimeOptions(),
    },
  },
  {
    key: 'prompt',
    type: 'cat-ai-textarea',
    props: {
      label: 'Prompt',
      rows: 7,
      placeholder: 'Write a prompt',
      maxLength: 400,
      description: '400 characters',
    },
    parsers: [(value) => (value && value.trim().length ? value : undefined)],
  },
];
