import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PrivateQuestion, QuizAnswer } from '@cat-ai-us-fe/api';

export type LocalPrivateQuestion = Omit<PrivateQuestion, 'variants'> & {
  hasCorrectAnswer?: boolean;
  hasUncorrentAnswer?: boolean;
  variants: {
    readonly id: number;
    text: string;
    correct?: boolean;
    correctOption?: boolean;
    nonCorrectOption?: boolean;
  }[];
};
@Component({
  selector: 'cat-ai-questions-list',
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: './questions-list.component.html',
})
export class QuestionsListComponent {
  @Input() questions!: LocalPrivateQuestion[] | undefined | null;
  @Input() answers!: QuizAnswer[] | undefined | null;
  @Input() uniqueId = '';
  @Input() documentMode = false;
}
