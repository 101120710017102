import { Component, Input, ViewChild } from '@angular/core';
import { TrainingsStatsRetrieve200Response } from '@cat-ai-us-fe/api';
import { SecondsToTimePipe } from '@cat-ai-us-fe/shared/util';
import * as Highcharts from 'highcharts';
import {
  HighchartsChartComponent,
  HighchartsChartModule,
} from 'highcharts-angular';

@Component({
  selector: 'cat-ai-training-detailed-stats',
  standalone: true,
  imports: [HighchartsChartModule, SecondsToTimePipe],
  templateUrl: './training-detailed-stats.component.html',
})
export class TrainingDetailedStatsComponent {
  @Input() chart!: Highcharts.Options;
  @Input() stats!: TrainingsStatsRetrieve200Response | undefined;
  @ViewChild(HighchartsChartComponent) chartEl!: HighchartsChartComponent;

  Highcharts: typeof Highcharts = Highcharts;
}
