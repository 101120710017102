import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SearchComponent, SelectorComponent } from '@cat-ai-us-fe/shared/ui';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  finalize,
  of,
  scan,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompanyGroup,
  JobCategory,
  PublicTraining,
  TrainingsListRequestParams,
} from '@cat-ai-us-fe/api';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { TrainingsListComponent } from '@cat-ai-us-fe/trainings/ui';

@Component({
  selector: 'cat-ai-trainings-list-view',
  standalone: true,
  imports: [
    SearchComponent,
    MatButtonModule,
    SelectorComponent,
    AsyncPipe,
    TrainingsListComponent,
  ],
  providers: [AsyncPipe],
  templateUrl: './trainings-list-view.component.html',
})
export class TrainingsListViewComponent implements OnInit, OnDestroy {
  loading = false;
  data$!: Observable<PublicTraining[]>;
  departments$!: Observable<CompanyGroup[]>;
  jobCategories$!: Observable<JobCategory[]>;
  pagination: Partial<TrainingsListRequestParams> = {
    pageSize: 10,
    page: 1,
  };
  private filterParams$ = new BehaviorSubject<TrainingsListRequestParams>(
    this.pagination,
  );
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: TrainingsService,
  ) {}

  ngOnInit() {
    this.handleDataStream();
    this.fetchSelectorsData();
  }

  onScrollDown() {
    if (this.loading) {
      return;
    }
    if (this.pagination.page) {
      this.pagination.page += 1;
    }
    this.updateFilters(this.pagination);
  }

  updateSearch(searchParam: string) {
    this.updateFilters({ search: searchParam, page: 1 });
  }

  create() {
    this.router.navigate(['../builder'], { relativeTo: this.route });
  }

  updateFilters(filters: TrainingsListRequestParams = {}) {
    this.filterParams$.next({ ...this.filterParams$.value, ...filters });
  }

  changeDepartment(id: number) {
    this.pagination.page = 1;
    this.updateFilters({ groups: [id], page: 1 });
  }

  changeJobCategory(id: number) {
    this.pagination.page = 1;
    this.updateFilters({ jobCategories: [id], page: 1 });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleDataStream() {
    this.loading = true;
    this.data$ = this.filterParams$.pipe(
      tap(() => (this.loading = true)),
      switchMap((filters) => {
        return this.apiService.getTrainings(filters).pipe(
          takeUntil(this.destroy$),
          catchError(() => {
            return of([]);
          }),
          finalize(() => (this.loading = false)),
          shareReplay(1),
        );
      }),
      scan((list: PublicTraining[], items: PublicTraining[]) => {
        if (this.pagination.page === 1) {
          return items;
        } else {
          return [...list, ...items];
        }
      }, []),
    );
  }

  private fetchSelectorsData() {
    this.jobCategories$ = this.apiService.getJobCategories();
    this.departments$ = this.apiService.getDepartments();
  }
}
