import {
  ColumnConfig,
  DateFormatPipe,
  DateFormatType,
  TableConfig,
} from '@cat-ai-us-fe/shared/util';
import { DetailTaskRead, TaskRead, User } from '@cat-ai-us-fe/api';
import { ChipComponent } from '@cat-ai-us-fe/shared/ui';

const datePipe = new DateFormatPipe();

export const chartOptions: Highcharts.Options = {
  chart: {
    plotBackgroundColor: '',
    plotBorderWidth: 0,
    plotShadow: false,
    backgroundColor: '',
  },
  title: {
    text: 'Users',
    align: 'center',
    verticalAlign: 'middle',
    y: 40,
    style: {
      fontSize: '15px',
      fontWeight: 'normal',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      borderWidth: 5,
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '110%'],
      size: '200%',
    },
  },
  colors: ['#12B76A', '#202939', '#F04438'],
  series: [],
  credits: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

export const columns: ColumnConfig<DetailTaskRead>[] = [
  {
    label: 'ID',
    html: (data: DetailTaskRead) => `# ${data.id}`,
  },
  {
    label: 'USER',
    html: (
      data: DetailTaskRead,
    ) => `<p class="font-bold">${data.assignee.first_name} ${data.assignee.last_name}</p>
    <p class="text-gray-500">${data.assignee.email}</p>`,
    roles: [User.RoleEnum.Supervisor],
  },
  {
    label: 'DESCRIPTION',
    html: (data: DetailTaskRead) => ` <p class="text-xs">${data.title} </p>`,
  },
  {
    label: 'STATUS',
    componentRef: ChipComponent,

    inputs: {
      bindedProperty: 'internal_status',
      colorsConfig: {
        [TaskRead.InternalStatusEnum.Todo]: 'gray',
        [TaskRead.InternalStatusEnum.Failed]: 'red',
        [TaskRead.InternalStatusEnum.Completed]: 'green',
        [TaskRead.InternalStatusEnum.InProgress]: 'blue',
        [TaskRead.InternalStatusEnum.Submitted]: 'orange',
        [TaskRead.InternalStatusEnum.Delayed]: 'blue',
        [TaskRead.InternalStatusEnum.NotStarted]: 'gray',
      },
    },
  },
  {
    label: 'DEPARTAMENT',
    roles: [User.RoleEnum.Supervisor],
    html: (data: DetailTaskRead) =>
      data.assignee.groups.length > 0
        ? data.assignee.groups.map((group) => group.name).join(', ')
        : 'No groups assigned',
  },
  {
    label: 'DUE DATE',
    html: (data: DetailTaskRead) =>
      datePipe.transform(data.end_date, DateFormatType.MonthDateYearAlt),
  },
];

export const config: TableConfig<DetailTaskRead> = {
  columns: columns,

  pagination: {
    page: 1,
    perPage: 6,
    itemsCount: 1,
  },
  actions: {
    search: true,
  },
};

export const STATS_MODEL = {
  usersTotal: 0,
  completed: 0,
  notStarted: 0,
  failed: 0,
  timeSpent: 0,
  certificates: 0,
  attempts: 0,
};
