import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  Attempt,
  Certificate,
  PrivateQuestion,
  TrainingsQuestionsAnswerUpdateRequestParams,
} from '@cat-ai-us-fe/api';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import {
  PassQuizIntroComponent,
  PassQuizQuestionsComponent,
  QuizResultsDialogComponent,
} from '@cat-ai-us-fe/trainings/ui';
import { SupervisorTraining } from '@cat-ai-us-fe/trainings/util';
import { Observable, filter, map, switchMap, tap } from 'rxjs';

enum PassQuizView {
  Intro,
  Quiz,
}

@Component({
  selector: 'cat-ai-pass-quiz-view',
  standalone: true,
  imports: [
    MatTabsModule,
    PassQuizIntroComponent,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    AsyncPipe,
    PassQuizQuestionsComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './pass-quiz-view.component.html',
})
export class PassQuizViewComponent implements OnInit, OnDestroy {
  loading = false;
  selectedView = PassQuizView.Intro;
  training$!: Observable<SupervisorTraining>;
  trainingId!: number;
  attemptId!: number | undefined;
  private destroyRef = inject(DestroyRef);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private trainingsService: TrainingsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscribeToTraining();
    window.addEventListener('beforeunload', this.promptIfSureToRedirect, false);
  }

  answerQuestion({
    id,
    trainingId,
    linkedEntitiesRequest,
  }: TrainingsQuestionsAnswerUpdateRequestParams) {
    this.trainingsService
      .updateQuestionAnswer(id, trainingId, linkedEntitiesRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const el = document.getElementById(`navigation-block-question-${id}`);
        if (linkedEntitiesRequest.ids.length) {
          el?.classList.add('answered');
        } else {
          el?.classList.remove('answered');
        }
      });
  }

  closeQuiz() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  finishAttempt(questions: PrivateQuestion[] | undefined) {
    const dialogRef = (data: Attempt) =>
      this.dialog
        .open(QuizResultsDialogComponent, {
          data: { attempt: data, questions: questions },
          height: '800px',
          minWidth: '600px',
        })
        .afterClosed();
    this.trainingsService
      .completeTraining(this.trainingId)
      .pipe(
        switchMap(() =>
          this.trainingsService.getAttempt(this.attemptId as number),
        ),
        switchMap((res) => dialogRef(res)),
      )
      .subscribe((res: Certificate | boolean | null) => {
        if (!res) {
          this.closeQuiz();
        } else {
          if ((res as Certificate).uuid) {
            this.gotoCertificate((res as Certificate).uuid);
          } else {
            this.selectedView = PassQuizView.Intro;
          }
        }
      });
  }

  private gotoCertificate(uuid: string) {
    this.router.navigate([`../certificate/${uuid}`], {
      relativeTo: this.route,
    });
  }

  startQuiz() {
    this.loading = true;
    this.trainingsService
      .createQuizAttempt(this.trainingId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.attemptId = res.attempt_id;
        this.selectedView = PassQuizView.Quiz;
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    window.removeEventListener(
      'beforeunload',
      this.promptIfSureToRedirect,
      false,
    );
  }

  private subscribeToTraining() {
    this.loading = true;
    this.training$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
      filter((id): id is string => !!id),
      tap((id) => (this.trainingId = +id)),
      switchMap((id) => this.trainingsService.getTraining(+id)),
      map((res) => this.mapTraining(res)),
      tap(() => (this.loading = false)),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private mapTraining(res: SupervisorTraining): SupervisorTraining {
    res.questions?.map((question) => {
      {
        question.single =
          question.variants?.filter((v) => v.correct).length > 1;
        return question;
      }
    });
    return res;
  }

  private promptIfSureToRedirect(event: any) {
    event.preventDefault();

    event.returnValue =
      'Are you sure you want to discard the quiz? -Something- will be lost.'; //TODO: define prompt text
    return event;
  }
}
