import { DatePipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { Material, MaterialLink } from '@cat-ai-us-fe/api';
import { FileNameFromUrlPipe } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-materials-list',
  standalone: true,
  imports: [
    DatePipe,
    MatIconModule,
    LowerCasePipe,
    TitleCasePipe,
    FileNameFromUrlPipe,
    RouterLink,
  ],
  templateUrl: './materials-list.component.html',
})
export class MaterialsListComponent {
  @Input() materials: MaterialLink[] | undefined = [];
  MaterialTypeEnum = Material.TypeEnum;
  materialIcons = {
    [Material.TypeEnum.Document]: 'insert_drive_file',
    [Material.TypeEnum.File]: 'insert_drive_file',
    [Material.TypeEnum.Flowchart]: 'schema',
    [Material.TypeEnum.Form]: 'article',
    PRESENTATION: 'co_present',
    VIDEO: 'videocam',
  };
}
