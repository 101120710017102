import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateFormatPipe } from '@cat-ai-us-fe/shared/util';
import { TaskRead } from '@cat-ai-us-fe/api';

@Component({
  selector: 'cat-ai-task-panel-item',
  standalone: true,
  imports: [DateFormatPipe],
  templateUrl: './task-panel-item.component.html',
})
export class TaskPanelItemComponent {
  @Input() task!: TaskRead;
  @Input() wrapperClasses!: string;
  @Output() taskClick = new EventEmitter();
}
