import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalPrivateQuestion } from '../questions-list/questions-list.component';

@Component({
  selector: 'cat-ai-quiz-navigation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quiz-navigation.component.html',
})
export class QuizNavigationComponent {
  @Input() questions!: LocalPrivateQuestion[] | undefined;
  @Input() wrapperClasses = '';
  @Input() uniqueId = '';
  @Input() showAnswers = false;

  scrollToQuestion(id: number) {
    const element = document.getElementById(
      `quiz-question-${this.uniqueId}-${id}`,
    );
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
}
