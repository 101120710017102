<div class="p-6 flex flex-col h-screen place-content-center">
  <button mat-button routerLink=".." class="!absolute top-4 left-4">
    <mat-icon class="relative -top-0.5">chevron_left</mat-icon>
    Back to Training
  </button>
  <div class="bg-white shadow-sm rounded-lg w-[800px] p-6 place-self-center">
    <h1>{{ training?.name }}</h1>
    <p class="text-lg">
      Number of questions: {{ training?.questions?.length }}
    </p>
    <br />
    <br />
    <h2>Attention</h2>
    <p class="text-lg">
      It is not allowed to use any prompts while passing the Quiz. Leaving the
      page is logged. After pressing "Start Quiz" button your attempt is
      started. If you are leaving the Quiz after pressing "Start Quiz" button,
      Quiz will be automatically closed after the time for passing it runs out.
      <br />
      <br />
      Good luck!
    </p>
    <br />
    <br />
    <h2>
      Time to pass the quiz:
      {{
        (training?.seconds_to_complete || 0) * 1000 | date: 'HH:mm:ss' : 'UTC'
      }}
    </h2>
    <br />
    <br />
    <button mat-flat-button color="accent" (click)="startQuiz.emit()">
      Start Quiz
    </button>
  </div>
</div>
