import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import {
  SearchComponent,
  SelectorComponent,
  TableComponent,
} from '@cat-ai-us-fe/shared/ui';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TABLE_CONFIG } from './config';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { User, UsersListRequestParams } from '@cat-ai-us-fe/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'cat-ai-add-participants-modal',
  standalone: true,
  imports: [
    SearchComponent,
    TableComponent,
    MatButtonModule,
    MatDialogModule,
    SelectorComponent,
    AsyncPipe,
  ],
  templateUrl: './add-participants-modal.component.html',
})
export class AddParticipantsModalComponent implements OnInit {
  config = TABLE_CONFIG;
  selectedParticipants: User[] = [];
  data$!: Observable<User[]>;
  destroyRef = inject(DestroyRef);
  loading = false;
  private filters$ = new BehaviorSubject<UsersListRequestParams>({
    pageSize: this.config.pagination.perPage,
    forTraining: this.data?.trainingId,
  });

  constructor(
    private apiService: TrainingsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { trainingId: number },
  ) {}

  ngOnInit(): void {
    this.handleDataStream();
  }

  setSelections(data: User[]) {
    this.selectedParticipants = data;
  }

  updateSearch(value: string) {
    this.updateFilters({ search: value, page: 1 });
  }

  changeDepartament(value: number) {
    this.updateFilters({ departament: value });
  }

  changeCategory(value: number) {
    this.updateFilters({ category: value });
  }

  changePage(page: number) {
    this.updateFilters({ page: page });
  }

  updateFilters(filters: any) {
    this.filters$.next({
      ...this.filters$.value,
      ...filters,
      pageSize: this.config.pagination.perPage,
      forTraining: this.data?.trainingId,
    });
  }

  handleDataStream() {
    this.data$ = this.filters$.pipe(
      tap(() => (this.loading = true)),
      switchMap((filters) => {
        return this.apiService.getUsers(filters).pipe(
          takeUntilDestroyed(this.destroyRef),
          tap((res) => (this.config.pagination.itemsCount = res.count)),
          map((r) => r.results),
          catchError(() => {
            return of([]);
          }),
          finalize(() => (this.loading = false)),
        );
      }),
    );
  }
}
